import Image from 'next/image'
import dynamic from 'next/dynamic'

const Hero = dynamic(() => import('./Hero'))
const BookingPage = dynamic(() => import('./booking/Booking'))
const Button = dynamic(() => import('../utils/Button'))

export default function HomePage({ getTerminals }) {
  const values = [
    {
      id: 1,
      name: 'Fair',
      image: '/images/u_money-stack.svg',
      description:
        'We strive to maintain fair prices, discounts, and a lot of freebies for new and existing customers.'
    },
    {
      id: 2,
      name: 'Secure',
      image: '/images/u_shield-check.png',
      description:
        'Top on our priority list is the safety of customers and their luggage. It is evident in the level of professionalism exercised by our drivers on the road and other staff members in their daily engagement with passengers and handling of haulage.'
    },
    {
      id: 3,
      name: 'Fast',
      description:
        'Our flexibility in the flow of information and communication allows for fast booking and a simplified customer journey. ',
      image: '/images/u_message.png'
    }
  ]
  return (
    <Hero>
      <section className='lg:my-24 md:my-8 md:px-20 lg:px-20 2xl:px-40 px-4 gap-4 md:grid md:grid-cols-2 lg:place-items-center'>
        <section className='flex-col pb-5 pt-20 md:p-0 items-center w-full text-center md:text-left'>
          <h1 className='font-regular text-5xl lg:6xl text-white'>
            A better way to
            <br /> travel
          </h1>
          <p className='text-white font-regular text-2xl lg:my-12 lg:w-11/12 my-12'>
            With comfort, affordability, and excellence as our watchwords, we
            are more than committed to providing a pleasant travel experience on
            every trip.
          </p>
          <section className='flex-row flex lg:justify-between w-full'>
            <section className='w-45 mr-6 lg:mr-0'>
              <Button
                link='/services'
                text='services'
                widthMD='full'
                fontWeight='bold'
                hover='hover'
                width='full'
              />
            </section>
            <section className='w-45'>
              <Button
                link='/about'
                text='about us'
                bg='transparent'
                textColor='priColor'
                widthMD='full'
                fontWeight='bold'
                groupHover='white'
                hover='hover'
                width='full'
              />
            </section>
          </section>
        </section>
        <section className='lg:w-32 px-6 lg:h-32 bg-mainColor rounded-lg hidden md:flex flex-col md:justify-self-end mt-10'>
          <section className='border-l border-r border-solid border-white h-14 w-2/5 mx-auto'></section>
          <section className='bg-white rounded-lg py-5 rotate-3 text-center text-base w-3/5 mx-auto flex items-center justify-center text-priColor rotateDiv font-bold lg:text-xl'>
            DISCOUNT PRICES
          </section>
          <p className='text-white mt-10 lg:mt-20 text-center text-xl'>
            {/* In line with our commitments to provide affordable fares, we are
            offering a further <span className='font-bold text-2xl'>7%</span> discount on
            all individual and group bookings and logistics services
            via our online channel www.anettransport.com, also check our social
            media channels for promo codes. */}
            Enjoy <span className='font-bold text-2xl'>5%</span> discount on all
            your bookings made through our website. <br />
            <br />
            Follow us on social media platforms to see our latest announcements
            and promos!
          </p>
        </section>
      </section>

      <BookingPage getTerminals={getTerminals} />
      <section className='bg-black px-6 py-16 md:grid grid-cols-3 lg:px-20 xl:px-40 md:py-8'>
        {values.map((value) => (
          <section
            className='flex flex-col md:items-start text-center md:text-left items-center mb-16'
            key={value.id}
          >
            <section className='flex items-center'>
              <section className='relative h-6 mr-2 w-6'>
                <Image
                  src={value.image}
                  alt={value.name}
                  layout='fill'
                  objectFit='cover'
                />
              </section>
              <p className='text-text-2xl font-bold text-white'>{value.name}</p>
            </section>
            <p className='text-base text-gray300 my-3 w-11/12'>
              {value.description}
            </p>
          </section>
        ))}
      </section>
    </Hero>
  )
}
